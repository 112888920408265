export const env = 'env5'
export const breakFixEnv = 'env7'
export const releaseEnv = 'env5'

export const environment: any = {
  agentEmailAppName: `PL`,
  agentEmailURL: `https://www-${env}.test.statefarm.com/agentemail`,
  accountLogout: `https://proofing-${env}.test.statefarm.com/login-interceptor/logout`,
  myAccountsURL: `https://apps-${env}.test.statefarm.com/my-accounts`,
  policyRetrieveApiURL: 'https://api.policy-retrieve-feature.test.statefarm.com'
}

if (window.location.href.includes('policy-view-dev')) {
  environment.policyRetrieveApiURL = `https://api.policy-retrieve-develop.test.statefarm.com`
} else if (window.location.href.includes('policy-view.test')) {
  ;(environment.agentEmailURL = `https://www-${releaseEnv}.test.statefarm.com/agentemail`),
    (environment.accountLogout = `https://proofing-${releaseEnv}.test.statefarm.com/login-interceptor/logout`),
    (environment.myAccountsURL = `https://apps-${releaseEnv}.test.statefarm.com/my-accounts`),
    (environment.policyRetrieveApiURL = `https://api.policy-retrieve-release.test.statefarm.com`)
} else if (window.location.href.includes('policy-view-breakfix')) {
  ;(environment.agentEmailURL = `https://www-${breakFixEnv}.test.statefarm.com/agentemail`),
    (environment.accountLogout = `https://proofing-${breakFixEnv}.test.statefarm.com/login-interceptor/logout`),
    (environment.myAccountsURL = `https://apps-${breakFixEnv}.test.statefarm.com/my-accounts`),
    (environment.policyRetrieveApiURL = `https://api.policy-retrieve-breakfix.test.statefarm.com`)
}

import { environment } from '@pv/constant'
import { Agent } from '@pv/dto'
import { LOCATION } from '../constants/app.constants'
import { agent$, authToken$, logger$ } from '../store'

export const openAgentWindowURL = () => {
  const agent: Agent = agent$()
  if (agent?.servicingAssociateID) {
    window.open(
      `${environment.agentEmailURL}/${agent.servicingAssociateID}/${environment.agentEmailAppName}`,
      undefined,
      'width=750, height=830, toolbars=no, menubar=no, location=yes, scrollbars=yes, addressbar=no, resizable=yes, status=no'
    )
  } else {
    const agentErrorMessage = 'Agent servicingAssociateID is not availble'
    logger$.set(agentErrorMessage)
    logout()
  }
}

export const logout = () => {
  authToken$.set(undefined)
  LOCATION.replace(environment.accountLogout)
}
